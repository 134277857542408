/**
 * @file It contains scripts for exceptions view
 */
import { mapGetters, mapActions } from "vuex"
import { getListOfStrings } from "@/utils"
import ThemisDecision from "@/components/shared/decision"
export default {
  name      : "Exceptions",
  components: { ThemisDecision },
  data() {
    return {
      filters                        : undefined,
      pendingExceptionsForTable      : new Array(),
      exceptionsHistoryForTable      : new Array(),
      exceptionToBeApprovedOrDeclined: undefined,
      exceptionHistory               : undefined,
      exceptionApproved              : undefined
    }
  },
  methods: {
    ...mapActions({
      updateException: "exceptions/updateException",
      notify         : "shared/notify"
    }),
    /**
     * This method is used custom filtering for vuetify data table.
     * This will be called for every cell value in the table.
     * @param {*} value is content of each cell in a the table
     */
    customFilterForTable(value) {
      return this.filters.findIndex(element => value && value.toString().includes(element)) + 1
    },
    handleUpdateException() {
      const exceptionId      = arguments[0]
      this.exceptionApproved = arguments[1]
      this.updateException({ id: exceptionId, status: this.exceptionApproved })
    },
    computeExceptionsForTable() {
      this.pendingExceptionsForTable = []
      this.exceptionsHistoryForTable = []
      let entity
      if (this.channels.length && this.translations.length && this.exceptions.length) {
        for (const exception of this.exceptions) {
          if (exception.entityName === "Translation") {
            entity = this.translationsMap[exception.entityId]
          }
          if(exception.status === this.$CONSTANTS.EXCEPTION_STATUS.OPEN) {
            this.pendingExceptionsForTable.push(
              { id         : exception.id,
                createdAt  : this.$moment(exception.createdAt).format("DD MMMM YYYY HH:mm"),
                type       : exception.type,
                channelName: this.channelsMap[entity.channelId]?.displayName,
                issueId    : entity.issueId,
                status     : exception.status
              }
            )
          } else {
            this.exceptionsHistoryForTable.push(
              { id         : exception.id,
                createdAt  : this.$moment(exception.createdAt).format("DD MMMM YYYY HH:mm"),
                type       : exception.type,
                channelName: this.channelsMap[entity.channelId]?.displayName,
                issueId    : entity.issueId,
                status     : exception.status
              }
            )
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      exceptions         : "exceptions/exceptions",
      isLoadingExceptions: "exceptions/isLoadingExceptions",
      translations       : "translations/translations",
      channels           : "channels/channels",
      languages          : "languages/languages",
      isUpdatingStatus   : "exceptions/isUpdatingStatus",
      isStatusUpdated    : "exceptions/isStatusUpdated"
    }),
    headersForTable() {
      return this.$TABLES.EXCEPTIONS.headers.map(header => {
        return {
          ...header, ...{
            text: this.$t(header.text)
          }
        }
      })
    },
    footersForTable() {
      return {
        ...this.$TABLES.EXCEPTIONS.footer, ...{
          itemsPerPageText: this.$t(this.$TABLES.EXCEPTIONS.footer.itemsPerPageText)
        }
      }
    },
    itemsForSearch() {
      return getListOfStrings(this.usersForTable)
    },
    translation() {
      return this.translations?.find(translation => translation.id === this.selectedException.entityId)
    },
    channelsMap() {
      const channelsMap = new Object()
      for (const channel of this.channels) {
        channelsMap[channel.id] = channel
      }
      return channelsMap
    },
    translationsMap() {
      const translationsMap = new Object()
      for (const translation of this.translations) {
        translationsMap[translation.id] = translation
      }
      return translationsMap
    },
    languagesMap() {
      const languagesMap = new Object()
      for (const language of this.languages) {
        languagesMap[language.id] = language
      }
      return languagesMap
    },
    selectedException() {
      let selectedException
      if (this.exceptionToBeApprovedOrDeclined) {
        selectedException =  this.exceptions?.find(exception => exception.id ===
          this.exceptionToBeApprovedOrDeclined.id)
      } else if(this.exceptionHistory) {
        selectedException =  this.exceptions?.find(exception => exception.id ===
          this.exceptionHistory.id)
      }
      if (selectedException) {
        selectedException.parsedData = JSON.parse(selectedException.data)
      }
      return selectedException
    },
    remainingDaysForException() {
      const currentDate                       = new Date()
      const exceptionCreatedDate              = new Date(this.exceptionToBeApprovedOrDeclined.createdAt)
      const numberOfDaysUntilExceptionCreated =  ((currentDate.getTime() - exceptionCreatedDate.getTime()) /
      this.$CONSTANTS.DATE.MILLISECONDS_IN_ONE_DAY).toFixed(0)
      return this.$CONSTANTS.DAYS_WITHIN_EXCEPTION_TO_BE_APPROVED - numberOfDaysUntilExceptionCreated
    }
  },
  watch: {
    filters: function(newFilters) {
      this.search = newFilters ? newFilters.toString() : undefined
    },
    exceptions: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.computeExceptionsForTable()
        }
      }
    },
    translationsMap: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.computeExceptionsForTable()
        }
      }
    },
    channelsMap: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.computeExceptionsForTable()
        }
      }
    },
    isUpdatingStatus: {
      handler: function(newValue) {
        if (this.exceptionApproved === this.$CONSTANTS.EXCEPTION_STATUS.APPROVED) {
          this.$DECISIONS.EXCEPTION.pActions[1].buttonProps.loading = newValue
        } else {
          this.$DECISIONS.EXCEPTION.pActions[0].buttonProps.loading = newValue
        }
      }
    },
    isStatusUpdated: {
      handler: function(newValue) {
        if (newValue) {
          if (this.exceptionApproved === this.$CONSTANTS.EXCEPTION_STATUS.APPROVED) {
            this.notify({
              type      : "success",
              text      : "120",
              parameters: {
                language: this.languagesMap[this.selectedException.parsedData.changedSourceLanguageId].name
              }
            })
          } else {
            this.notify({
              type      : "success",
              text      : "121",
              parameters: {
                language: this.languagesMap[this.selectedException.parsedData.changedSourceLanguageId].name
              }
            })
          }
          this.exceptionToBeApprovedOrDeclined = undefined
          this.exceptionApproved               = undefined
        }
      }
    }
  }
}